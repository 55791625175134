<template>
  <div>
    <v-banner
        v-model="showBanner"
        single-line
        transition="slide-y-transition"
        shaped
        class="font-italic"
    >
      Wählen Sie einen Komptenzbereich.
    </v-banner>
    <v-scroll-x-transition>
      <v-carousel
          transition="scale-transition"
          ref=vpanel
          v-model="model"
          hide-delimiters
          v-show="openVideoPanel"
          :show-arrows="false"
          height="auto"
      >

        <v-carousel-item
            v-for='(observationcat, i) in items'
            :key="i"
        >
          <v-btn
              v-show="openVideoPanel"
              right
              absolute
              top
              fab
              text
              style="top: 0;right:0;"
              @click="openVideoPanel = !openVideoPanel; activeCard=-1;showBanner=true; onCloseObs()"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <VideoPanel
              v-model="$store.state.selecteddrivingclass"
              v-show="openVideoPanel"
              :title=observationcat.name
              :description=observationcat.desc
              :video_src=getMp4Name(i)
          >
          </VideoPanel>

        </v-carousel-item>
      </v-carousel>
    </v-scroll-x-transition>


    <v-spacer class="ma-3"></v-spacer>
    <v-row v-show="true">
      <v-col
          v-for='(observationcat, i) in items'
          :key="i"
          cols="6"
          class="col-obscat5"
          @click="showCarouselItem(i)"
      >
        <CardGridObsCat
            :title=observationcat.name
            :img_src=observationcat.img_src
            :video_src=observationcat.video_src
            :propsdata=observationcat
            :key="i"
            :class="activeCard === i ? getActiveBGColorOfCard :''"
        ></CardGridObsCat>

      </v-col>
    </v-row>
    <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        shaped
        outlined
        color="primary"
        elevation="12"

    >
      In diesem Kompetenzbereich existiert kein Film.

    </v-snackbar>
  </div>
</template>

<script>
import Vue from "vue";
import CardGridObsCat from "@/components/items/CardGridObsCat.vue";
import VideoPanel from "@/components/panels/VideoPanel.vue";
import axios from "axios";

export default Vue.extend({
  name: "ObservationCatGrid",
  components: {
    VideoPanel,
    CardGridObsCat,
  },
  data: () => ({
    openVideoPanel: false,
    VideoPanelP: {
      title: '',
      src: '',
      video_poster: '',
      description: '',
      showPanel: false
    },
    items: [],
    model: 0,
    activeCard: -1,
    description: "",
    showBanner: false,
    snackbar: false,
    timeout: 2000,
  }),
  props: ['dclassProp'],
  computed: {
    getActiveBGColorOfCard(){
      if(this.$vuetify.theme.dark) return  'grey darken-2'
      else return 'grey lighten-2'
    },
  },
/*  created() {
     this.getObscats();
  },*/
  mounted() {
    this.activeCard = -1;
    this.getObscats();
 },
  methods: {
    getMp4Name(i){
      let name = this.getNameOfVideoOrTranscript(i+1);
      if(name.length === 0) {
        return "";
      }
      return  name+'.mp4';
    },
    getObscats() {
      this.items = this.$store.state.observationcats
      for(let it in this.items){
        this.getTranscriptForVideoObscat(parseInt(it)+1);
      }

    },
    showCarouselItem(i) {
      let name = this.getNameOfVideoOrTranscript(i+1);
      if(name.length === 0) {
        this.snackbar = true;
        return;
      }
      this.model = i;
      this.activeCard = i;
      this.openVideoPanel = true;
      this.onCloseObs();
      this.$nextTick(() => {
        this.$vuetify.goTo('#breadcrumbcat')
        this.showBanner = false;
      });
    },
    getNameOfVideoOrTranscript(obscatid) {
      let sitsubarray=["I","II","III","IV"]
      let dt = this.$route.params.dtid;
      let sdt = this.$route.params.sdtid==="0" ? 1 :  this.$route.params.sdtid;
      let obscat = obscatid;
      let sitsub = this.$route.params.sitsubid  === undefined ? "":"-"+sitsubarray[this.$route.params.sitsubid-1] ;
      let name = dt + "." + sdt + "." + 2 + "." + obscat + "" + sitsub
      return this.getVideoNameForDrivingClass(name);
    },
    getVideoNameForDrivingClass(name){
      const json_fe = this.$store.state.fe_video_map;
      let vname= '';
      for (let key in json_fe){
        if(key.includes(name)){
          //vname= name;

          if(json_fe[key].split(',').includes(this.$store.state.selecteddrivingclass)){
            return key.toString();
          }
        }
      }
      return vname;
    },
    getTranscriptForVideoObscat(obscatid){
      let name = this.getNameOfVideoOrTranscript(obscatid);
      if(name.length === 0){
        let backup = this.items;
        backup[obscatid-1].desc = "In diesem Kompetenzbereich der Fahrerlaubnisklasse "+this.$store.state.selecteddrivingclass+" existiert kein Film.";
        this.items = [];
        this.items = backup;
        return;
      }
      let path = name+".json";
      axios
          .get("/json/transcript/" + path)
          .then(response => {
            let backup = this.items;
            backup[obscatid-1].desc = response.data.transcript;
            this.items = [];
            this.items = backup;
          })
    },
    onCloseObs() {
        const collection = Array.from(document.getElementsByTagName("video"));
        collection.forEach(function (videop){
          videop.pause();
        })
    }
  },
  watch: {
    dclassProp: function(newVal, oldVal) {
      this.getObscats()
    }
  },
  activated() {
    this.showBanner = true;
    this.getObscats();
  },
  deactivated() {
    this.showBanner = false;
  }

});
</script>

<style scoped>
@media (min-width: 961px) {
  .col-obscat5 {
    flex: 0 0 20%;
    max-width: 20%;
  }
}

</style>